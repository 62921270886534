import React from 'react'
import './gal.css'
const GalCard = ({image, caption}) => {
  return (
        <div className='gal__wrapper'
      
        >
            <div className='gal__img'>
                <img src={image}/>

             
            </div>
            <p className='gal__caption'> 
                {caption}
            </p>
        </div>
   
  )
}

export default GalCard