import React, { useCallback, useEffect, useRef } from 'react'
import './gallery.css'
import 'react-slideshow-image/dist/styles.css'
import { Fade, Zoom, Slide } from 'react-slideshow-image'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import Images from './Images'
import loo from '../../assets/let2.JPG'
import ImageSlider from '../../components/ImageSlider/ImageSlider'
import { FaArrowAltCircleRight, FaArrowAltCircleLeft} from 'react-icons/fa'
import Gal from './Gal'
import GalCard from '../../components/Gal/GalCard'
import { Helmet } from 'react-helmet-async'
const Gallery = () => {
  const timerRef = useRef(null)
  const[current, setCurrent] =React.useState(0)
  const {picture} = Images[current]
  const length = Images.length

  useEffect(() => {
    const preloadImages = () => {
      Images.forEach((image) => {
        const img = new Image();
        img.src = image.picture;
      });
    };

    preloadImages();
  }, []);

  const nextSlide = useCallback(() => {
    setCurrent(prevSlide => (prevSlide + 1) % length);
  }, [length]);

const prevSlide = () => {
  setCurrent(prev => prev - 1)
  if(current <= 0)
  {
    setCurrent(length - 1)
  }
}
useEffect(() => {
  let isMounted = true;

  if (timerRef.current) {
    clearTimeout(timerRef.current);
  }

  timerRef.current = setTimeout(() => {
    if (isMounted) {
      nextSlide();
    }
  }, 3000);

  return () => {
    clearTimeout(timerRef.current);
    isMounted = false;
  };
}, [nextSlide]);

  return (
    <div id='gallery' className='gallery__container'>
       
       <Helmet>
      <title>
        Letties Guest House
      </title>
      <meta name='Description' 
      content='Welcome To Letties Guest House, Are you looking for overnight accommodation? Go through our gallery.'/>
      <link rel="canonical" href='/' />
    </Helmet>
      <div className="gallery__head">
        <h1>Gallery</h1>
      </div>
      <div className="gallery__info">
        <h1>Our Rooms</h1>
        <p className='para'
        
        >
      Nestled amidst pristine natural surroundings, the luxurious lodge exudes opulence, 
      elegance, and sophistication. Its grand architecture and lavish interiors create an exquisite 
      ambiance that transports guests into a world of refined indulgence. The stately lodge stands 
      as a testament to its prestigious status, offering majestic accommodations and unparalleled 
      service.
        </p>
        <div className="gal__card">
          {
            Gal.map((picture, index)=>{
              return <GalCard key={index}
              image = {picture.image}
              caption = {picture.caption}
              />
            })
          }
        </div>

</div>
      <div className="gallery__slide"
  
      >
        <div>
        <FaArrowAltCircleLeft className='left_arrow' onClick={prevSlide} />
        </div>
      
        
        
            
          
          <div className='anim'>
              <img src={picture} className='image' alt={`Slide ${current + 1}`} />
              </div>
        
         
         
           <div>
           <FaArrowAltCircleRight className='right_arrow' onClick={nextSlide} />
           </div>
                
       
      </div>
         


    </div>
  )
}

export default Gallery