export const Images = [
    {
        id:1,
        picture: require('../../assets/let1.JPG'),
        caption: 'one'
    },
    {
        id:2,
        picture: require('../../assets/let2.JPG'),
        caption: 'two'
    },
    {
        id:3,
        picture: require('../../assets/let3.JPG'),
        caption: 'three'
    },
    {
        id:4,
        picture: require('../../assets/let4.JPG'),
        caption: 'four'
    },
    {
        id:5,
        picture: require('../../assets/let5.JPG'),
        caption: 'one'
    },
    {
        id:6,
        picture: require('../../assets/let6.jpg'),
        caption: 'one'
    },
    {
        id:7,
        picture: require('../../assets/let7.JPG'),
        caption: 'two'
    },
    {
        id:8,
        picture: require('../../assets/let8.JPG'),
        caption: 'three'
    },
    {
        id:9,
        picture: require('../../assets/let9.JPG'),
        caption: 'four'
    },
    {
        id:10,
        picture: require('../../assets/let10.JPG'),
        caption: 'one'
    },
    {
        id:11,
        picture: require('../../assets/let11.JPG'),
        caption: 'one'
    }
]
export default Images;