import React from 'react'
import './footer.css'
import Logo from '../../assets/letties4-removebg.svg'
import { Link } from 'react-scroll'
const Footer = () => {
  return (
    <div className='footer__container'>
      <div className="footer__desc">
        <h2>From the upscale amenities to the palatial decor, the lodge exudes a sense of magnificence 
          and charm. It is a haven of fine taste, where distinguished 
          guests can immerse themselves in the lap of luxury and savor the epitome of refined living.</h2>
      </div>
      <div className="footer__wrapper">
        <div className="footer__left">
        <Link to='home'  duration={400} smooth={true}  >
        <img src={Logo} alt="" /></Link>
        </div>
        <div className="footer__center">
          <h1>Quick Links</h1>
        <div className='footer__links'>
                <ul>
                <Link to='home'  duration={400} smooth={true}  >
                    <li>
                        
                            Home 
                    </li>
                    </Link>
                    <Link to='accommodation'  duration={400} smooth={true} offset={-60}  >
                    <li>
                        
                            Accommodation
                    </li>
                    </Link>
                    <Link to='gallery'  duration={400} smooth={true} offset={-100}>
                    <li>
                       
                        Gallery 
                    </li>
                    </Link>
                    <Link to='contact'  duration={400} smooth={true}  offset={-150}  >
                    <li>
                        
                        Contact 
                    </li>
                    </Link>
                </ul>
            </div>
        </div>
        <div className="footer__right">
        <div className="contact__address">
                <h3>Address:</h3>
                <h1>
                  15 Karee Avenue
                </h1>
                <h1>
                Kings Hill Harrismith
                </h1>
                <h1>
                9880
                </h1>
                <h3>Phone:</h3>
                <h1>
                  072 042 8309
                </h1>
                <h3>Email:</h3>
                <h1>
                info@lettiesguesthouse.co.za
                </h1>
          </div>
        </div>
      </div>

      <div className="foot__desc">
  <div className="copyright">
          <h3>CopyRights Reserved © 2023 </h3>
         <h4>Letties Guest House, Harrismith</h4>
  </div>
  <div className="powered">
          <h5>Powered by CaraCaraZW</h5>
  </div>
</div>
    </div>


  )
}

export default Footer