import React from 'react'
import { Link } from 'react-scroll'
import Logo from '../../assets/letties4-removebg.svg'
import {CiMenuFries} from 'react-icons/ci'
import {RiCloseLine} from 'react-icons/ri'
import './navbar.css'
import { useEffect } from 'react'
const Navbar = () => {
    const [newmenu, setMenu] = React.useState(false);

    const [navbarColor, setNavbarColor] = React.useState('transparent')
    useEffect(()=>{
        const changeNavbarColor = () => {
            const offset = window.scrollY;
            const threshold = 100;

            if (offset > threshold){
                setNavbarColor('#181820')
            }
            else{
                setNavbarColor('transparent')
            }
        }
        window.addEventListener('scroll', changeNavbarColor);
        return()=> {
            window.removeEventListener('scroll', changeNavbarColor);
        }
    }, [])

   


  return (
    <div className='navbar__container' style={{backgroundColor: navbarColor}}>
        <div className='navbar__main'>
            <div className='logo'>
            <Link to='home'  duration={400} smooth={true} >
                       
                <img src={Logo} alt="" />
                </Link>
            </div>
            <div className='links'>
                <ul>
                    <li>
                        <Link to='home' spy={true} activeClass='active' duration={400} smooth={true}  >
                            Home </Link>
                    </li>
                    <li>
                        <Link to='accommodation' spy={true} activeClass='active' duration={400} smooth={true} offset={-60} >
                            Accommodation </Link>
                    </li>
                    <li>
                        <Link to='gallery' spy={true} activeClass='active' duration={400} smooth={true} offset={-120}  >
                        Gallery </Link>
                    </li>
                    <li>
                        <Link to='contact' spy={true} activeClass='active' duration={400} smooth={true} offset={-180}  >
                        Contact </Link>
                    </li>
                </ul>
            </div>
            <div className='toogle__but'>
                <button onClick={()=> setMenu(prevMenu => !prevMenu)}>
                   {
                    newmenu ? <RiCloseLine color='red' />  : <CiMenuFries  color='blue' /> 
                   } 
                    
                </button>
             
       {
            newmenu &&     
            <ul className={`navbar__links scale-up-hor-right ${newmenu ? 'show_nav': 'hide_nav'}`} onClick={()=>setMenu(false)}>
            <li>
                <Link to='home' spy={true} activeClass='active' duration={400} smooth={true} onClick={()=>setMenu(false)} >
                    Home </Link>
            </li>
            <li>
                <Link to='accommodation' spy={true} activeClass='active' duration={400} smooth={true} onClick={()=>setMenu(false)} offset={-60} >
                    Accommodation </Link>
            </li>
            <li>
                <Link to='gallery' spy={true} activeClass='active' duration={400} smooth={true} onClick={()=>setMenu(false)} offset={-100} >
                Gallery </Link>
            </li>
            <li>
                <Link to='contact' spy={true} activeClass='active' duration={400} smooth={true} onClick={()=>setMenu(false)} offset={-150} >
                Contact </Link>
            </li>
        </ul>
        }
        </div>
            </div>
        
 
    </div>
  )
}

export default Navbar