export const Gal = [{
    id: 1,
    image: require('../../assets/gal1.JPG'),
    caption: 'Double Room'
},
{
    id: 2,
    image: require('../../assets/gal2.JPG'),
    caption: 'Living Room'
},
{
    id: 3,
    image: require('../../assets/gal3.JPG'),
    caption: 'Dining Room'
},
{
    id: 4,
    image: require('../../assets/gal4.JPG'),
    caption: 'Single Room'
}

]

export default Gal;