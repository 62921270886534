import React from 'react'
import './home.css'
import Accommodation from '../Accommodation/Accommodation'
import Gallery from '../Gallery/Gallery'
import Contact from '../Contact/Contact'
import { Link } from 'react-scroll'
import { Helmet } from 'react-helmet-async'
const Home = () => {
  return (
    <>
    <Helmet>
      <title>
        Letties Guest House
      </title>
      <meta name='Description' 
      content='Welcome To Letties Guest House, Are you looking for overnight accommodation? Come and visit our Lodge for the best service.'/>
      <link rel="canonical" href='/' />
    </Helmet>
    <div className='home' id="home">
    <div className="container__home"  >
         <div className='heading1'>
            <h1>Welcome to &nbsp; <span data-text="Letties">Letties</span></h1> 
            <h1>Guest House</h1>
            <h3>Discover our world-class hotel & restaurant resort</h3>
         
        </div>
        <Link to='accommodation'  duration={400} smooth={true} > 
            <button className='home__but'>
           
               Explore The Beauty 
                 
           </button>
           </Link>
    </div>
 
    </div>

    <Accommodation/>
    <Gallery/>
    <Contact/>
    </>
  )
}

export default Home