import React from "react";
import { BrowserRouter, HashRouter, Routes, Route } from "react-router-dom";

import Navbar from "./components/Navbar/Navbar";
import Home from "./pages/Home/Home";
import Accommodation from "./pages/Accommodation/Accommodation";
import Gallery from "./pages/Gallery/Gallery";
import Contact from "./pages/Contact/Contact";
import Footer from "./components/Footer/Footer";
import './App.css'

function App(){
    return(
        <div className="app__bg" >
            <HashRouter>
                <Navbar/>
                <Routes>
                    <Route exact path="/" Component={Home}/>
                    <Route path="/Accommodation" Component={Accommodation}/>
                    <Route path="/Gallery" Component={Gallery}/>
                    <Route path="/Contact" Component={Contact} />
                </Routes>
                <Footer/>
            </HashRouter>
        </div>
    )
}
export default App;