import React from 'react'
import './ims.css'
const ImageSlider = ({slides}) => {

  const [current, setCurrent] = React.useState(1)

  const sld = {
    height:"100%",
    position: 'relative'
  }
  const slideStyles = {
    width: '100%',
    height: '100%',
    borderRadius: '10px',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundImage: `url(${slides[current]})`
  }
  return (
    <div style={sld}>
      <div style={slideStyles}>

      </div>
    </div>
  )
}

export default ImageSlider