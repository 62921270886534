import React from 'react'
import './accommodation.css'
import acc1 from '../../assets/acc1.jpg'
import acc2 from '../../assets/acc2.jpg'
import { Helmet } from 'react-helmet-async'
const Accommodation = () => {
  return (
    
    <div id='accommodation' className='accommodation__container'>
       <Helmet>
      <title>
        Letties Guest House Accommodation
      </title>
      <meta name='Description' content='Welcome To Letties Guest House, Are you looking for overnight accommodation? Go through our price list.'/>
      <link rel="canonical" href='/Accommodation' />
    </Helmet>
      <h1 className='acc_head'>Accommodation </h1>
      <div className='accommodation__wrapper'>
            <div className='accommodation__top' 
            >
              <div className='accommodation__top_image'>
                <img src={acc1} alt='Image'/>
              </div>
              <div className="accommodation__top_content" data-aos="zoom-in-up"
             data-aos-easing="ease-out-cubic"
             data-aos-duration="800">
                <h1>
                  5 Star Lodge
                </h1>
                <p>
                Excellent Self-Catering Guest House in Harrismith, 
                Free State with easy access to the N3 and N5 highway. 
                A perfect resting place half way between Johannesburg and Durban. 
                The Guest House consist of 5 bedrooms, 2 bathrooms, dining room, kitchen and a lovely living room. 
                There is ample parking area enough for 5 cars and 3 of which are parked.
                </p>
                <br />
                <p>
                The kitchen contains cooking utensils, cookware, fridge, stove, oven, and more. 
                Living room has television connected to the internet. 
                There is a beautiful garden with a jungle gym for childern to enjoy.
                </p>

                <h3>Rates Per Night</h3>
                <h3>SELF CATERING</h3>
                <ul>
                  <li>Single  <span>R700</span></li>
                  
                  <li>Whole House <span>R2500</span></li>
                </ul>
                <h3>CATERING</h3>
                <ul>
                  <li>Breakfast  <span>R150</span></li>
                  
                  <li>Dinner <span>R250</span></li>
                </ul>
              </div>
            </div>
            <div className="accommodation__bottom"
           
            >
                
                <div className="accommodation__bottom_content"  data-aos="zoom-in-up"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="800">
                  <h1>Guest House Standards</h1>
                  <ul>
                    <li>
                      bed
                    </li>
                    <li>
                      Wifi Connection
                    </li>
                    <li>
                      Television
                    </li>
                    <li>
                      Fully Equipped Kitchen
                    </li>
                    <li>
                      Backup Water Tank
                    </li>
                    <li>
                      Private security Alarm
                    </li>
                    <li>
                      Outside Jungle gym
                    </li>
                  </ul>
                </div>
                <div className="accommodation__bottom_image">
                <img src={acc2} alt='Image'/>
                </div>
            </div>
      </div>
   
      
    </div>
  )
}

export default Accommodation