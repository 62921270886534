import React, { useRef } from 'react';
import emailjs from "emailjs-com"
import './contact.css'
import { Helmet } from 'react-helmet-async';
const Contact = () => {

  

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_oifvwou', 'template_40v92a9', e.target, 'ggzKoKATm5Hb6uYg5')
      .then((result) => {
          alert("Message Sent");
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset()
  };
  return (
    <div className='contact__container' id='contact'>
       <Helmet>
      <title>
        Letties Guest House
      </title>
      <meta name='Description' 
      content='Welcome To Letties Guest House, Are you looking for overnight accommodation? Contact Us on: 072 042 8309 .'/>
      <link rel="canonical" href='/' />
    </Helmet>
      <h1 className='contact__head'
     
      >Get In Touch</h1>
        <div className="contact__wrapper">
        
         
          <div className="contact__form"
            data-aos="fade-up-right"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="800"
         
          >
                <form action="" className='form__control' onSubmit={sendEmail}>
                  <div className='details'>
                      <div className='name'>
                        <label htmlFor="name">
                          Name
                        </label>
                        <input type="text" placeholder='Name' name='name'/>
                      </div>
                      <div className="cellNo">
                      <label htmlFor="cell">
                          Cell No
                        </label>
                        <input type="text" placeholder='Cell Number' name='cell' />
                      </div>
                  </div>
                  <div className="email">
                  <label htmlFor="email">
                          Email
                        </label>
                        <input type="email" placeholder='Email' name='email'/>
                  </div>
                  <div className="content">
                    <label htmlFor="Message">
                      Message
                    </label>
                    <textarea name="message" id=""  rows="6"></textarea>
                  </div>
                  <div className='form__bt'>
                  <button className='form__but'>
                    Send
                  </button>
                  </div>
                
                </form>
          </div>
        
        
          <div className="contact__address"
        
          >
                <h3>Address:</h3>
                <h1>

                  15 Karee Avenue
                </h1>
                <h1>
                Kings Hill Harrismith
                </h1>
                <h1>
                9880
                </h1>
                <h3>Phone:</h3>
                <h1>
                  072 042 8309
                </h1>
                <h3>Email:</h3>
                <h1>
                  info@lettiesguesthouse.co.za
                </h1>
          </div>
        
        </div>
        <div className="map"
       
        >
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3513.5981805868723!2d29.139896275178927!3d-28.28019847585903!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1ef29dfef091fb55%3A0xd12a5a32e8b99712!2s15%20Karee%20Ave%2C%20Kings%20Hill%2C%20Harrismith%2C%209880!5e0!3m2!1sen!2sza!4v1689007498585!5m2!1sen!2sza"
          height="450" 
          allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
          </div>
    </div>
  )
}

export default Contact
